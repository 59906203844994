export const REACT_APP_SWING_API_URL = process.env.REACT_APP_SWING_API_URL;

export const createPrescription = (data: any) => {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  let raw = JSON.stringify(data);

  let requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
  };

  fetch(`${REACT_APP_SWING_API_URL}/user/createPrescription`, requestOptions)
    .then(response => response.text())
    .then(data => console.log(data))
    .catch(error => console.log('error', error));
}

export const deactivatePrescription = (data: any) => {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  let raw = JSON.stringify(data);

  let requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
  };

  fetch(`${REACT_APP_SWING_API_URL}/user/deactivatePrescription`, requestOptions)
    .then(response => response.text())
    .then(data => console.log(data))
    .catch(error => console.log('error', error));
}

export const reactivatePrescription = (data: any) => {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  let raw = JSON.stringify(data);

  let requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
  };

  fetch(`${REACT_APP_SWING_API_URL}/user/reactivatePrescription`, requestOptions)
    .then(response => response.text())
    .then(data => console.log(data))
    .catch(error => console.log('error', error));
}

export const refillPrescription = (data: any) => {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  let raw = JSON.stringify(data);

  let requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
  };

  fetch(`${REACT_APP_SWING_API_URL}/user/schedulePrescriptionRefill`, requestOptions)
    .then(response => response.text())
    .then(data => console.log(data))
    .catch(error => console.log('error', error));
}