export const StanzaAndroidLink = "https://bit.ly/3yT5SlF";
export const StanzaIOSLink = "https://apple.co/3JUtvk3";

const twilioConfig = {
  accountSID: "AC6f3cee029ddc4dea52508f1785631e8b",
  authToken: "89da69ab5be2c33a2aae64af58c1a2c4",
  phoneNumber: "+15178789093",
}

export const sendText = (body: string, to: string) => {
  let myHeaders = new Headers();
  myHeaders.append("Authorization", `Basic ${btoa(twilioConfig.accountSID + ":" + twilioConfig.authToken)}`);
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  let urlencoded = new URLSearchParams();
  urlencoded.append("Body", body);
  urlencoded.append("From", twilioConfig.phoneNumber);
  urlencoded.append("To", to);

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
  };

  fetch(`https://api.twilio.com/2010-04-01/Accounts/${twilioConfig.accountSID}/Messages`, requestOptions)
    .then(response => response.text())
    .then(data => console.log(data))
    .catch(error => console.log(error));
}